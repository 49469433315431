<template>
  <ul class="page">
    <li class="chevron-btn" :class="{ active: currentPage != 1 }" @click="setCurrentPage(currentPage - 1)">
      <span data-icon="chevron_left">
        <vue-material-icon name="chevron_left" />
      </span>
    </li>
    <li class="page-numbers" @click="setCurrentPage(1)" v-if="currentPage > 3">{{ pageList[0] }}</li>
    <li class="page-dots" v-if="currentPage > 3">...</li>
    <li
      class="page-numbers"
      v-for="(pageNumber, index) in displayedPages"
      @click="setCurrentPage(pageNumber)"
      :class="{ active: currentPage === pageNumber }"
      :key="index"
    >
      {{ pageNumber }}
    </li>
    <li class="page-dots" v-if="currentPage < pageList.length - 2">...</li>
    <li class="page-numbers" @click="setCurrentPage(pageList.length)" v-if="currentPage < pageList.length - 2">
      {{ pageList[pageList.length - 1] }}
    </li>
    <li class="chevron-btn" :class="{ active: currentPage < pageList.length }" @click="setCurrentPage(currentPage + 1)">
      <span data-icon="chevron_right">
        <vue-material-icon name="chevron_right" />
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['page', 'totalPageCount'],
  data() {
    return {
      currentPage: null,
      pageList: [],
    };
  },
  computed: {
    displayedPages() {
      // if currentPage is page 1
      if (this.currentPage === 1) {
        return this.pageList.slice(this.currentPage - 1, this.currentPage + 4);
      }
      // if currentPage is the last page
      else if (this.currentPage === this.pageList.length) {
        return this.pageList.slice(this.currentPage - 5, this.currentPage + 1);
      }
      // if currentPage is between 4-7
      else if (this.currentPage >= 4 && this.currentPage <= 7) {
        return this.pageList.slice(this.currentPage - 2, this.currentPage + 1);
      }
      // if currentPage more than 7
      else if (this.currentPage > 7) {
        return this.pageList.slice(this.currentPage - 4, this.currentPage + 1);
      }
      // if currentPage less than 4
      else {
        return this.pageList.slice(this.currentPage - 2, this.currentPage + 3);
      }
    },
  },
  mounted() {},
  created() {
    this.currentPage = Number(this.page);
    this.setPageList();
  },
  watch: {
    page() {
      this.currentPage = Number(this.page);
    },
    totalPageCount() {
      this.setPageList();
    },
  },
  methods: {
    setPageList() {
      this.pageList = [];
      for (let i = 1; i <= this.totalPageCount; i++) {
        this.pageList.push(i);
      }
    },
    setCurrentPage(pageNum) {
      this.currentPage = pageNum;
      this.$emit('setPageNum', this.currentPage);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import 'Pagination';
</style>
