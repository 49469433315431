var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "page" },
    [
      _c(
        "li",
        {
          staticClass: "chevron-btn",
          class: { active: _vm.currentPage != 1 },
          on: {
            click: function ($event) {
              return _vm.setCurrentPage(_vm.currentPage - 1)
            },
          },
        },
        [
          _c(
            "span",
            { attrs: { "data-icon": "chevron_left" } },
            [_c("vue-material-icon", { attrs: { name: "chevron_left" } })],
            1
          ),
        ]
      ),
      _vm.currentPage > 3
        ? _c(
            "li",
            {
              staticClass: "page-numbers",
              on: {
                click: function ($event) {
                  return _vm.setCurrentPage(1)
                },
              },
            },
            [_vm._v(_vm._s(_vm.pageList[0]))]
          )
        : _vm._e(),
      _vm.currentPage > 3
        ? _c("li", { staticClass: "page-dots" }, [_vm._v("...")])
        : _vm._e(),
      _vm._l(_vm.displayedPages, function (pageNumber, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "page-numbers",
            class: { active: _vm.currentPage === pageNumber },
            on: {
              click: function ($event) {
                return _vm.setCurrentPage(pageNumber)
              },
            },
          },
          [_vm._v(" " + _vm._s(pageNumber) + " ")]
        )
      }),
      _vm.currentPage < _vm.pageList.length - 2
        ? _c("li", { staticClass: "page-dots" }, [_vm._v("...")])
        : _vm._e(),
      _vm.currentPage < _vm.pageList.length - 2
        ? _c(
            "li",
            {
              staticClass: "page-numbers",
              on: {
                click: function ($event) {
                  return _vm.setCurrentPage(_vm.pageList.length)
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.pageList[_vm.pageList.length - 1]) + " ")]
          )
        : _vm._e(),
      _c(
        "li",
        {
          staticClass: "chevron-btn",
          class: { active: _vm.currentPage < _vm.pageList.length },
          on: {
            click: function ($event) {
              return _vm.setCurrentPage(_vm.currentPage + 1)
            },
          },
        },
        [
          _c(
            "span",
            { attrs: { "data-icon": "chevron_right" } },
            [_c("vue-material-icon", { attrs: { name: "chevron_right" } })],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }